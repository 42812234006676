import MUIDataTable from "mui-datatables";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Sidebar1 from "../../../../../components/sidebar/sidebar";
import Header from "../../../../../components/header/header";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {
  getalldeals,
  getDealDetailsByPaymentDate,
} from "../../../../../servies/services";
import { TrainRounded } from "@material-ui/icons";
import Loader from "../../../../../components/loader";
import NumberComp2 from "../../../../../components/NumberComp2";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import * as moment from "moment";
import { data } from "jquery";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ReactModal from "react-modal";
import BeanEater from "../../../../../images/BeanEater.gif";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: sessionStorage.getItem("token"),
      tableData: [],
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      singleRowPaymentDate: {},
      loading: false,
      getLoansLoader: false,
      screenloader: false,
      PaymentDate: "",
      anchorEl: null,
      servicerValues: [],
      paymentDates: [],
      getPaymentDateIndex: null,
      getlastPaymentDates: [],
      getDashboardVersions: [],
      isDateChanged: false,
      isDateSelected: null,
      showSearchBox: false,
      searchText: "",
      versionSelected: "",
      versions: [],
      openModalPayment: false,
      DealName: "",
      clickSide: false,
      singleRowVersion: {},
    };
  }
  AddDeal = () => {
    sessionStorage.setItem("isAddDeal", true);
    this.props.history.push({
      pathname: "/admin/general",
      // state: { checkDeal: true },
    });
  };
  Recurring = (value, tableMeta) => {
    console.log(this.state.PaymentDate, this.state.getlastPaymentDates);
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", tableMeta);
    sessionStorage.setItem("dealname", value);
    sessionStorage.setItem(
      "nextpaymentdate",
      this.state.PaymentDate === ""
        ? tableMeta.rowData[1][0]
        : this.state.PaymentDate
    );
    sessionStorage.setItem("isdisable", true);
    sessionStorage.setItem("isLoansProcessed", tableMeta.rowData[3]);
    let index = this.state.getlastPaymentDates.indexOf(this.state.PaymentDate);
    sessionStorage.setItem(
      "lastpaymentdate",
      this.state.isDateChanged === true
        ? this.state.getlastPaymentDates[index + 1] === undefined
          ? ""
          : this.state.getlastPaymentDates[index + 1]
        : tableMeta.rowData[1][1] === undefined
        ? ""
        : tableMeta.rowData[1][1]
    );
    sessionStorage.setItem("Servicer", tableMeta.rowData[8]);
    sessionStorage.setItem(
      "version",
      this.state.versionSelected === ""
        ? tableMeta.rowData[2][0]
        : this.state.versionSelected
    );
    sessionStorage.setItem(
      "getDashboardDeals",
      JSON.stringify(this.state.tableData.map((item) => item["Deal Name"]))
    );
    sessionStorage.setItem(
      "getDashboardDates",
      JSON.stringify(tableMeta.rowData[1])
    );
    sessionStorage.setItem(
      "getDashboardVersions",
      JSON.stringify(tableMeta.rowData[2])
    );
    this.props.history.push({
      pathname: "/admin/general_recurring",
      state: { checkLoadStatus: true },
    });
  };
  ViewDetails = (value, tableMeta) => {
    console.log(tableMeta.rowData[1]);
    sessionStorage.setItem("dealname", value);
    sessionStorage.setItem("assettype", tableMeta.rowData[7]);
    sessionStorage.setItem(
      "version",
      this.state.versionSelected === ""
        ? tableMeta.rowData[2][0]
        : this.state.versionSelected
    );
    sessionStorage.setItem(
      "getDashboardDeals",
      JSON.stringify(this.state.tableData.map((item) => item["Deal Name"]))
    );
    sessionStorage.setItem(
      "getDashboardDates",
      JSON.stringify(tableMeta.rowData[1])
    );
    sessionStorage.setItem(
      "getDashboardVersions",
      JSON.stringify(tableMeta.rowData[2])
    );    this.props.history.push({
      pathname: "/admin/viewdetails",
      state: {
        details:
          this.state.PaymentDate === ""
            ? tableMeta.rowData[1][0]
            : this.state.PaymentDate,
        servicerList: this.state.servicerValues,
      },
    });
  };
  General = (value) => {
    sessionStorage.setItem("dealname", value);
    this.props.history.push({
      pathname: "/admin/general",
      state: { checkDeal: false },
    });
  };
  getalldeals = async () => {
    var data = {};
    data.peer = this.state.peer;
    console.log("datata", data);
    this.setState({ getLoansLoader: true, tableData: [], loading: true });
    const APIResponse = await getalldeals(data);

    if (APIResponse.status === 200) {
      this.setState(
        {
          getLoansLoader: false,
          tableData: APIResponse.data,
          loading: false,
        },
        () => {
          const dealData = APIResponse.data;
          if (dealData.length > 0) {
            const getDealNames = dealData.map((item) => item["Deal Name"]);
            const getPaymentDates = dealData.map(
              (item) => item["Payment Date"]
            )[0];
            const getServicers = dealData.map((item) => item["Servicer"])[0];
            const getVersions = dealData.map((item) => item["Version"])[0];
            sessionStorage.setItem("dealname", getDealNames[0]);
            sessionStorage.setItem("isLoansProcessed", "Yes");
            console.log(">>>>>>>>>>>>>>>>>>", getPaymentDates[0]);
            console.log("check", getDealNames[0]);
            sessionStorage.setItem(
              "nextpaymentdate",
              getPaymentDates[getPaymentDates.length - 1]
            );
            sessionStorage.setItem("lastpaymentdate", "");
            sessionStorage.setItem("Servicer", getServicers[0]);
            sessionStorage.setItem("version", getVersions[0]);
            sessionStorage.setItem(
              "getDashboardDeals",
              JSON.stringify(getDealNames)
            );
            sessionStorage.setItem(
              "getDashboardDates",
              JSON.stringify(getPaymentDates)
            );
            sessionStorage.setItem(
              "getDashboardVersions",
              JSON.stringify(getVersions)
            );
            sessionStorage.setItem("dashChanges", true);
          } else {
            console.log("Deal Name or Payment Date is missing.");
            sessionStorage.setItem("dealname", "");
            sessionStorage.setItem("isLoansProcessed", "No");
            sessionStorage.setItem("nextpaymentdate", "");
            sessionStorage.setItem("lastpaymentdate", "");
            sessionStorage.setItem("Servicer", "");
            sessionStorage.setItem("version", "");
            sessionStorage.setItem("getDashboardDeals", "");
            sessionStorage.setItem("getDashboardDates", "");
            sessionStorage.setItem("getDashboardVersions", "");
            sessionStorage.setItem("dashChanges", false);
          }
        }
      );

      // const message = "Deal Document Update Success";
      // this.props.enqueueSnackbar(message, {
      //   variant: "info",
      //   autoHideDuration: 3000,
      // });
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  onchange = (e) => {
    e.preventDefault();
    const searchText = e.target.value;
    this.setState({ searchText: searchText });
    console.log("search: e.target.value", this.state.searchText);
  };

  searchBar = () => (
    <div className="search-bar-main-container" id="searchBox">
      <div className="tableSearch1">
        <TextField
          value={this.state.searchText}
          onChange={this.onchange}
          placeholder="Search"
          color="#212121"
          variant="standard"
          size="small"
        />
      </div>
      <button
        type="button"
        onClick={this.onClickCloseSearchBar}
        className="close-mui-icon"
      >
        <CloseIcon />
      </button>
    </div>
  );

  onClickCloseSearchBar = () => {
    this.setState({ searchText: "" });
    this.setState({ showSearchBox: false });
  };
  onClickSearchButton = () => {
    this.setState({ showSearchBox: true });
  };
  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTable: {
          root: {
            border: "none !important",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "rgb(229,229,229,0.3) !important",
            },
            "&.Mui-selected": {
              backgroundColor: "white !important",
            },
          },
          hoverCursor: {
            cursor: "auto !important",
          },
        },
        MuiTableCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            padding: "20px",
            fontSize: "0.980rem !important",
          },
        },
        MuiCircularProgress: {
          colorSecondary: {
            color: "#048c88 !important",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            borderBottom: "none !important",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "rgba(1, 142, 130, 0.1) !important",
            borderBottom: "none !important",
            paddingBottom: "5px !important",
            paddingTop: "5px !important",
            paddingLeft: "15px !important",
          },
          toolButton: {
            fontFamily: "Catamaran !important",
            fontWeight: "600 !important",
            fontSize: "15px !important",
            backgroundColor: "none !important",
            padding: "none !important",
            marginLeft: "none !important",
            textTransform: "none !important",
            border: "none !important",
            borderRadius: "none !important",
          },
        },
        MUIDataTableToolbar: {
          root: {
            fontFamily: "Mulish !important",
            paddingLeft: "5px !important",
            paddingRight: "5px !important",
          },
          titleText: {
            fontFamily: "Mulish !important",
            fontSize: "28px",
            color: "#212121",
            fontWeight: "600",
            fontFamily: "arial",
            marginBottom: "20px",
            marginTop: "20px",
          },
          icon: {
            color: "#018E82",
            paddingRight: "14px !important",
            "&:hover": {
              color: "#018E82 !important",
            },
          },
          iconActive: {
            color: "#018E82 !important",
          },
        },
        MuiButton: {
          contained: {
            backgroundColor: "#FFC000 !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          outlined: {
            backgroundColor: "#fff !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          label: {
            fontSize: "15px !important",
            // padding: "0px 20px !important",
            // width:"20rem",
            fontWeight: "600 !important",
            fontFamily: "Mulish !important",
          },
          textPrimary: {
            color: "#018E82 !important",
          },
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            borderBottom: "none !important",
          },
          navContainer: {
            justifyContent: "center",
          },
          toolbar: {
            paddingLeft: "50px !important",
            zIndex: "1 !important",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "#018E82 !important",
          },
          active: {
            color: "#018E82 !important",
          },
        },
        MuiTablePagination: {
          caption: {
            color: "#8C8C8C",
            marginRight: `${
              this.state.currentPage >= 1 && this.state.currentPage <= 9
                ? "-138"
                : this.state.currentPage >= 10
                ? "-142"
                : "-130"
            }px`,
            fontSize: "0.80rem",
          },
        },
        MuiIconButton: {
          colorInherit: {
            color: "#018E82 !important",
            zIndex: "1000",
            marginRight: "60px",
            paddingLeft: "-25px",
          },
        },
        MUIDataTable: {
          paper: {
            boxShadow: "none !important",
          },
          responsiveBase: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
          },
        },
        MuiInput: {
          root: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
            padding: "5px !important",
          },
          underline: {
            "&:after": {
              borderBottom: "none !important",
            },
            "&:before": {
              borderBottom: "none !important",
            },
          },
        },
      },
    });

  handlePaymentDateChange = async (event, value, rowIndex, tableData) => {
    const newValue = event.target.value;
    console.log("newValue", newValue);
    const updatedPaymentDates = [...this.state.paymentDates];
    updatedPaymentDates[rowIndex] = newValue;

    // console.log("PaymentDates", value);

    console.log("updatedPaymentDates", updatedPaymentDates);

    this.setState(
      {
        getlastPaymentDates: value,
        isDateChanged: true,
        getPaymentDateIndex: rowIndex,
        PaymentDate: newValue,
        openModalPayment: true,
        paymentDates: updatedPaymentDates,
        singleRowPaymentDate: tableData,
      },
      () => console.log("rowData", this.state.singleRowPaymentDate)
    );
  };

  updatePaymentDateValue = async (newValue, rowData) => {
    console.log("newValue", newValue, rowData);
    var data = {};
    var input = {};
    input["Deal Name"] = rowData[0];
    input["Payment Date"] = rowData[1];
    input["Version"] = rowData[2];
    input["Loans Processed"] = rowData[3];
    input["Deals Processed"] = rowData[4];
    input["Last Modified"] = rowData[6];
    input["Asset Class"] = rowData[7];
    input["Servicer"] = rowData[8];
    console.log("input", input);
    data.input = input;
    data.selectedDate = newValue;
    data.peers = this.state.peers;
    console.log("data", data);
    // this.setState({ PaymentDate: newValue });
    this.setState({ singleRowPaymentDate: rowData });
    // this.setState({ dateNeeded: rowData });

    const APIResponse = await getDealDetailsByPaymentDate(data);
    console.log("APIResponse", APIResponse);

    if (APIResponse.status === 200) {
      const res = APIResponse.data;
      const modifiedData = this.state.tableData.map((item, index) => {
        if (item["Deal Name"] === rowData[0]) {
          return res;
        } else {
          return item;
        }
      });
      console.log("modifiedData", modifiedData);
      this.setState(
        {
          getLoansLoader: false,
          tableData: modifiedData,
          loading: false,
          openModalPayment: false,
        },
        () => {
          console.log("singleRowPaymentDate", this.state.tableData);
        }
      );

      // const message = "Deal Document Update Success";
      // this.props.enqueueSnackbar(message, {
      //   variant: "info",
      //   autoHideDuration: 3000,
      // });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        openModalPayment: false,
      });
      console.log("", this.state.PaymentDate);

      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handleVersionChange = async (event, value, tableData) => {
    const newValue = event.target.value;
    console.log("newValue", newValue);

    const updatedVersions = [...this.state.versions];
    updatedVersions[tableData[2]] = newValue;

    console.log("updatedVersions", updatedVersions);

    this.setState({
      versionSelected: updatedVersions[tableData[2]],
      versions: updatedVersions,
      singleRowVersion: tableData,
      getDashboardVersions: value,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    // Check if the PaymentDate state has changed
    if (
      prevState.PaymentDate !== this.state.PaymentDate ||
      prevState.getPaymentDateIndex !== this.state.getPaymentDateIndex
    ) {
      //|| prevState.singleRowPaymentDate !== this.state.singleRowPaymentDate
      // Perform the API call or other logic here
      const { PaymentDate, singleRowPaymentDate } = this.state;
      console.log("rowData", singleRowPaymentDate);

      this.updatePaymentDateValue(PaymentDate, singleRowPaymentDate);
    }
  }

  async componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);
    sessionStorage.removeItem("dealname");
    sessionStorage.setItem("isTabChange", false);
    sessionStorage.setItem("isClosingTape", false);
    sessionStorage.setItem("AddedContent", []);
    sessionStorage.setItem("TranchItems", []);
    sessionStorage.setItem("isESMA_Flag", "No");
    this.getalldeals();
  }

  render() {
    const options = {
      filterType: "dropdown",
      filter: false,
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      onRowClick: this.onRowClick,
      onRowSelectionChange: this.onRowSelectionChange,
      onChangePage: this.onChangePage,
      onRowHover: this.onRowHover,
      rowsSelected: this.state.rowsSelected,
      rowsPerPage: 10,
      rowsPerPageOptions: false,
      jumpToPage: false,
      pagination: TrainRounded,

      onRowSelectionChange: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map((row) => row.dataIndex) });
        const selected = allRows.map((row) => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },

      onChangePage: (currentPage) => {
        console.log("currentPage", currentPage);
        this.setState({ currentPage: currentPage });
      },

      searchText: this.state.searchText,
      searchPlaceholder: "Search",
      customSearch: (searchQuery, currentRow, columns) => {
        const lowercaseSearchQuery = searchQuery.toLowerCase();

        // Assuming the "Deal Name" column is the first column (index 0)
        const dealName =
          currentRow[0] !== undefined
            ? currentRow[0].toString().toLowerCase()
            : "";

        // Check if the "Deal Name" contains the search query
        const isFound = dealName.includes(lowercaseSearchQuery);

        return isFound;
      },

      loading: false,
      textLabels: {
        body: {
          noMatch:
            this.state.loading === true ? (
              <Loader msg={"Please wait, Loading Deal Data"} />
            ) : (
              "Sorry, there is no matching data to display"
            ),
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },

        selectedRows: {
          text: "row(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Rows",
        },
        pagination: {
          next: "Next ",
          previous: "Previous",
          rowsPerPage: "",
          displayRows: "Of",
        },
      },
    };

    const handlePopoverOpen = (event, value) => {
      console.log("event", event.currentTarget, value);
      this.setState({
        anchorEl: event.currentTarget,
        servicerValues: value,
      });
    };

    const getAllDates = (date, Index, tableMeta) => {
      console.log("date", date, Index, tableMeta);
      const changeDateFormat =
        Index === tableMeta.rowIndex
          ? moment(date).format("MM/DD/YYYY").toString()
          : null;
      this.setState({ dateNeeded: Index });
      console.log("changeDateFormat", changeDateFormat);
      return changeDateFormat;
    };

    const getIndex = (index) => {
      console.log("index", index);
      this.setState({ isDateSelected: index });
      return index;
    };

    const handlePopoverClose = () => {
      this.setState({ anchorEl: null });
    };

    const open = Boolean(this.state.anchorEl);

    const columns = [
      {
        name: "Deal Name",
        label: "Deal Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Payment Date",
        label: "Payment Date",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            // console.log("paymentDate", value,tableMeta);

            return (
              <React.Fragment>
                <div>
                  <select
                    className="input-select-general-new2"
                    value={this.state.paymentDates[tableMeta.rowIndex]}
                    onChange={(e) =>
                      this.handlePaymentDateChange(
                        e,
                        value,
                        tableMeta.rowIndex,
                        tableMeta.rowData
                      )
                    }
                  >
                    {value.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Version",
        label: "Version",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            // console.log("version", value);
            // const handleVersionChange = (event) => {
            //   const newValue = event.target.value;
            //   updateValue(newValue);
            //   // this.setState({ versionSelected: newValue });
            //   // TODO: Trigger API call with the new value
            // };

            return (
              <React.Fragment>
                <div>
                  {value.every((item) => item !== "") ? (
                    <select
                      className="input-select-general-new2"
                      value={this.state.versions[tableMeta.rowIndex]}
                      onChange={(e) =>
                        this.handleVersionChange(e, value, tableMeta.rowData)
                      }
                    >
                      {value.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <select
                      className="input-select-dashboard"
                      disabled
                      // value={value}
                      // onChange={handleVersionChange}
                    >
                      {value.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </React.Fragment>
            );
          },
        },
      },

      // {
      //   name: "LastPaymentDate",
      //   label: "Last Payment Date",
      //   options: {
      //     filter: true,
      //     sort: true,
      //     // customHeadRender: (columnMeta, updateDirection) => (
      //     //   <th
      //     //     style={{
      //     //       backgroundColor: "rgba(1, 142, 130, 0.1)",
      //     //       borderBottom: "none",
      //     //       paddingBottom: "5px",
      //     //       paddingTop: "5px",
      //     //       paddingLeft: "20px",
      //     //     }}
      //     //   >
      //     //     {columnMeta.label}
      //     //   </th>
      //     // ),
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       // console.log("value", value);
      //       return (
      //         <React.Fragment>
      //           <div className="align-actions1">
      //             {value !== "" ? (
      //               <MuiPickersUtilsProvider utils={MomentUtils}>
      //                 <KeyboardDatePicker
      //                   disableToolbar
      //                   margin="normal"
      //                   id="date1"
      //                   value={
      //                     this.state.LastPaymentDate === ""
      //                       ? value
      //                       : this.state.LastPaymentDate
      //                   }
      //                   onChange={(date) =>
      //                     this.setState({
      //                       LastPaymentDate: moment(date)
      //                         .format("MM/DD/YYYY")
      //                         .toString(),
      //                     })
      //                   }
      //                   keyboard
      //                   placeholder="MM/DD/YYYY"
      //                   format={"MM/DD/YYYY"}
      //                   disableopenonenter
      //                   animateYearScrolling={false}
      //                   autoOk={true}
      //                   clearable
      //                   variant="filled"
      //                   helperText={""}
      //                 />
      //               </MuiPickersUtilsProvider>
      //             ) : (
      //               ""
      //             )}
      //           </div>
      //         </React.Fragment>
      //       );
      //     },
      //   },
      // },

      {
        name: "Loans Processed",
        label: "Loans Processed",
        options: {
          filter: true,
          sort: true,
          // customHeadRender: (columnMeta, updateDirection) => (
          //   <th
          //     style={{
          //       backgroundColor: "rgba(1, 142, 130, 0.1)",
          //       borderBottom: "none",
          //       paddingBottom: "5px",
          //       textAlign:"center",
          //       paddingTop: "5px",
          //       paddingLeft: "2rem",
          //     }}
          //   >
          //     {columnMeta.label}
          //   </th>
          // ),
          customBodyRender: (value, tableMeta, updateValue) => (
            <div
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingRight: "2rem",
              }}
            >
              {value}
            </div>
          ),
        },
      },
      {
        name: "Deals Processed",
        label: "Deal Processed",
        options: {
          filter: true,
          sort: true,
          // customHeadRender: (columnMeta, updateDirection) => (
          //   <th
          //     style={{
          //       backgroundColor: "rgba(1, 142, 130, 0.1)",
          //       borderBottom: "none",
          //       paddingBottom: "5px",
          //       textAlign:"center",
          //       paddingTop: "5px",
          //       paddingLeft: "2rem",
          //     }}
          //   >
          //     {columnMeta.label}
          //   </th>
          // ),
          customBodyRender: (value, tableMeta, updateValue) => (
            <div
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingRight: "2rem",
              }}
            >
              {value}
            </div>
          ),
        },
      },
      {
        name: "Deal Name",
        label: "Actions",
        options: {
          filter: false,
          sort: false,
          customHeadRender: (columnMeta, updateDirection) => (
            <th
              style={{
                backgroundColor: "rgba(1, 142, 130, 0.1)",
                borderBottom: "none",
                paddingBottom: "5px",
                paddingTop: "5px",
                paddingLeft: "1.5rem",
              }}
            >
              {columnMeta.label}
            </th>
          ),
          customBodyRender: (value, tableMeta, updateValue) => {
            // console.log("tabe meta", tableMeta.rowData[5]);
            return (
              <React.Fragment>
                <div className="align-actions">
                  {/* <Button variant="outlined" id="optionalbutton" type="submit" onClick={this.onOpenModal1.bind(this)}> */}
                  {/* <button className="popupdashboard" onClick={() => this.Servicer()}>View Servicers</button> */}
                  <button
                    className="popupdashboard"
                    onClick={() => this.Recurring(value, tableMeta)}
                  >
                    Deal
                  </button>
                  <button
                    className="popupdashboard"
                    onClick={() => this.ViewDetails(value, tableMeta)}
                  >
                    Loans
                  </button>
                  {/* {tableMeta.rowData[5] == "Enable" ? ( */}

                  {/* ) : (
                    <button
                      disabled
                      type="button"
                      className="login-sign_up-links button-disabled"
                    >
                      Update Deal
                    </button>
                  )} */}
                  {/* <button
                    className="popupdashboard"
                    onClick={() => this.General(value)}
                  >
                    Edit Deal
                  </button> */}
                  {/* </Button> */}
                </div>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Last Modified",
        label: "Last Modified",
        options: {
          filter: true,
          sort: true,
          // customHeadRender: (columnMeta, updateDirection) => (
          //   <th
          //     style={{
          //       backgroundColor: "rgba(1, 142, 130, 0.1)",
          //       borderBottom: "none",
          //       paddingBottom: "5px",
          //       textAlign:"center",
          //       paddingTop: "5px",
          //       paddingLeft: "2rem",
          //     }}
          //   >
          //     {columnMeta.label}
          //   </th>
          // ),
          customBodyRender: (value, tableMeta, updateValue) => (
            <div
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingRight: "2rem",
              }}
            >
              {value}
            </div>
          ),
        },
      },

      {
        name: "Asset Class",
        label: "Asset Class",
        options: {
          filter: true,
          sort: true,
          // customHeadRender: (columnMeta, updateDirection) => (
          //   <th
          //     style={{
          //       backgroundColor: "rgba(1, 142, 130, 0.1)",
          //       borderBottom: "none",
          //       paddingBottom: "5px",
          //       textAlign:"center",
          //       paddingTop: "5px",
          //       paddingLeft: "2rem",
          //     }}
          //   >
          //     {columnMeta.label}
          //   </th>
          // ),
          customBodyRender: (value, tableMeta, updateValue) => (
            <div
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingRight: "2rem",
              }}
            >
              {value}
            </div>
          ),
        },
      },
      {
        name: "Servicer",
        label: "Servicer",
        options: {
          filter: true,
          sort: true,
          // customHeadRender: (columnMeta, updateDirection) => (
          //   <th
          //     style={{
          //       backgroundColor: "rgba(1, 142, 130, 0.1)",
          //       borderBottom: "none",
          //       paddingBottom: "5px",
          //       textAlign:"center",
          //       paddingTop: "5px",
          //       paddingLeft: "2rem",
          //     }}
          //   >
          //     {columnMeta.label}
          //   </th>
          // ),
          customBodyRender: (value, tableMeta, updateValue) => {
            // console.log("value", tableMeta, value.length === this.state.tableData.map((item,index)=>item.Servicer.length)[tableMeta.rowIndex]);
            // console.log(tableMeta);
            // console.log(tableMeta.rowIndex,tableMeta.currentTableData.map((item,index)=>index)[tableMeta.rowIndex]);

            return (
              <React.Fragment>
                <div
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={(e) => handlePopoverOpen(e, value)}
                  onMouseLeave={handlePopoverClose}
                  style={{
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingRight: "3rem",
                    cursor: "pointer",
                  }}
                >
                  <ul className="servicer_items">
                    <li>
                      {[value[0], value[1], value[2]]
                        .filter((item) => item !== undefined)
                        .join(", ")}
                    </li>
                    {/* <li>{value[1]}</li>
                    <li>{value[2]}</li> */}
                  </ul>
                </div>

                {this.state.servicerValues.length > 3 ? (
                  <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: "none",
                    }}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    {this.state.servicerValues
                      .map((item, index) => (
                        <Typography
                          sx={{ p: 2 }}
                          key={index}
                          style={{ margin: "1rem" }}
                        >
                          {item}
                        </Typography>
                      ))
                      .filter((item, index) => index > 2)}
                  </Popover>
                ) : null}
              </React.Fragment>
            );
          },
        },
      },

      {
        name: "ProcessDeal",
        label: "Process Deal",
        options: {
          filter: true,
          sort: true,
          display: false,
        },
      },
    ];
    return (
      <div className="page">
        <Sidebar1 activeComponent={"Dashboard"} />
        <div className="content">
          <div className="header">
            <Header></Header>
          </div>
          <div className="page-content">
            <div className="row row1">
              <div className="investor-heading-container">
                {this.state.showSearchBox == true ? (
                  this.searchBar()
                ) : (
                  <h1 className="headerdashboard">Dashboard</h1>
                )}
              </div>
              <div>
                <div className="dashboard-top-right-container1">
                  <div className="search-bar-outer-container uw-search-button-container">
                    {this.state.showSearchBox == false && (
                      <button
                        className="search-mui-icons"
                        type="button"
                        onClick={this.onClickSearchButton}
                      >
                        <SearchOutlinedIcon />
                      </button>
                    )}
                    {
                      <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={this.AddDeal}
                      >
                        Add Deal
                      </Button>
                    }
                  </div>
                </div>
              </div>
            </div>
            <React.Fragment>
              <div className="workbench-table-container">
                <MuiThemeProvider theme={this.getMuiTheme()}>
                  <MUIDataTable
                    // title={'Dashboard'}
                    data={this.state.tableData}
                    columns={columns}
                    options={options}
                  />
                </MuiThemeProvider>
              </div>
              <ReactModal
                isOpen={this.state.openModalPayment}
                contentLabel="Minimal Modal Example"
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the alpha value for desired transparency
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "fixed", // Use fixed positioning for the overlay
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 9999, // Set a high z-index to ensure the modal appears on top
                  },
                  content: {
                    position: "absolute",
                    background: "transparent", // Make the modal content transparent
                    border: "none", // Optional: Remove any borders
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    overflow: "hidden",
                  },
                }}
              >
                <React.Fragment>
                  <img
                    src={BeanEater}
                    style={{
                      width: "8vw",
                      height: "9vh",
                      backgroundColor: "transparent",
                    }}
                  />
                  <h3 className="dot-loader">Loading.Please wait</h3>
                </React.Fragment>
              </ReactModal>
            </React.Fragment>
          </div>
        </div>
      </div>
    );
  }
}

export default withSnackbar(Dashboard);
